//
@import '~ngx-toastr/toastr';

//
@import "styles/spacer";

$accent-yellow: rgb(255, 195, 0);
$indigo: rgb(0, 170, 225);
$indigo-dark: rgb(20, 60, 140);
$red-muted: #b9280a;

.yellow {
    color: $accent-yellow;
}

.indigo-clr {
    color: $indigo;
}

.indigo-dark-clr {
    color: $indigo-dark;
}

.yellow-bg {
    background: $accent-yellow;
}

.white-svg {
    svg {
        path {
            fill: #FFFFFF;
        }

        width: 150px;
        height: 150px;

        @media screen and (max-width: 480px) {
            width: 100px;
            height: 100px;

        }
    }
}


/**
 * boeppel
 */
.boeppel {
    position: absolute;

    top: -6px;
    right: -4px;

    width: 18px;
    height: 18px;

    border: 3px solid white;
    border-radius: 10px;

    background: $accent-yellow;
}

.boeppel-list {
    position: absolute;

    top: -6px;
    right: 8px;

    width: 16px;
    height: 16px;

    border: 2px solid white;
    border-radius: 10px;

    background: $accent-yellow;
}

.icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iic {
    position: relative !important;
}


/**
 * layout
 */

.row {
    display: flex;
    flex-direction: row;
}

.col {
    display: flex;
    flex-direction: column;
}

/**
 * Button
 */

.iona-button {
    display: inline-block;
    background: transparent;

    width: 240px;
    padding: 13px 10px;

    text-decoration: none;
    font-size: 21px;
    text-align: center;

    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    border: 2px solid white;

    color: white;

    &:hover {
        color: $accent-yellow;
    }
}


/**
 * general
 */
.fh {
    height: 100%;
}

.fw {
    width: 100%;
}
//
//.highcharts-tooltip {
//    stroke: black;
//    fill: rgba(255, 255, 255, 0.6);
//}


.cursor-pointer {
    cursor: pointer;
}

.center-contents {
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-align-contents {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.inflate {
    width: 100%;
    height: 100%;
}

// regular style toast

.toast-container .ngx-toastr {
    box-shadow: none !important;
}

